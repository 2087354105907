import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Player } from 'video-react';

import 'video-react/dist/video-react.css';

const useStyles = makeStyles(() => ({
  player: {
  },
}));

const VideoPlayer = ({ cover, video }) => {
  const classes = useStyles();
  return (
    <Player
      class={classes.player}
      playsInline={false}
      muted
      autoPlay
      poster={cover}
      src={video}
      loop
    />
  );
};

VideoPlayer.propTypes = {
  cover: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};

export default VideoPlayer;
