import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff1ff',
      main: '#e1bee7',
      dark: '#af8eb5',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  status: {
    success: 'green',
    danger: 'orange',
  },
});

export default theme;
