/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  iconButton: {
    flex: 1,
  },
}));

const Sharer = ({ title, url }) => {
  const classes = useStyles();
  const spreadableProps = { url, title };
  return (
    <div className={classes.container}>
      <FacebookShareButton
        quote={title}
        url={url}
        className={classes.iconButton}
      >
        <FacebookIcon
          size={32}
          round
        />
      </FacebookShareButton>
      <TwitterShareButton
        {...spreadableProps}
        className={classes.iconButton}
      >
        <TwitterIcon
          size={32}
          round
        />
      </TwitterShareButton>
      <RedditShareButton
        {...spreadableProps}
        className={classes.iconButton}
      >
        <RedditIcon
          size={32}
          round
        />
      </RedditShareButton>
    </div>
  );
}

Sharer.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Sharer;
