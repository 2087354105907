import {
  shape,
  string,
  number,
} from 'prop-types';

export const userShape = shape({});
export const itemShape = shape({});
export const leaderboardShape = shape({});

export const galleryEntryShape = shape({
  id: string.isRequired,
  ownedBy: userShape.isRequired,
  leaderboard: leaderboardShape.isRequired,
  item: itemShape,
  result: string.isRequired,
  maxStress: number.isRequired,
  budgetUsed: number.isRequired,
  video: string.isRequired,
  videoPreview: string.isRequired,
});
