/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import autoPlay from 'react-swipeable-views-utils/lib/autoPlay';
import virtualize from 'react-swipeable-views-utils/lib/virtualize';
import bindKeyboard from 'react-swipeable-views-utils/lib/bindKeyboard';
import SwipeableViews from 'react-swipeable-views';

const modulo = (a, n) => ((a % n) + n) % n;

const VirtualizeSwipeViews = bindKeyboard(virtualize(SwipeableViews));
const VirtualizeAutoPlaySwipeViews = autoPlay(VirtualizeSwipeViews);

const carouselSlideRenderer = (children) => ({ index, key }) => React.cloneElement(
  children[modulo(index, children.length)], { key },
);

// eslint-disable-next-line react/prop-types
export default function SwipeableView({ children, ...other }) {
  const slideRenderer = carouselSlideRenderer(children);
  return (false) ? (
    <VirtualizeAutoPlaySwipeViews
      {...other}
      slideRenderer={slideRenderer}
    />
  ) : (
    <VirtualizeSwipeViews
      {...other}
      slideRenderer={slideRenderer}
    />
  );
}
