import Api from './Api';

export default class InternalAPIs extends Api {
  readAll(page = null) {
    if (!page) {
      return this.instance.get(
        'public/gallery/entries/read/all',
      );
    }
    return this.instance.get(
      `public/gallery/entries/read/all?page=${page}`,
    );
  }

  readOneSurrounded(entryId) {
    return this.instance.get(
      `public/gallery/entries/read/one/${entryId}/surrounded`,
    );
  }
}
