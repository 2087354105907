import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { grey } from '@material-ui/core/colors';
import withStyles from '@material-ui/core/styles/withStyles';
import { duration } from '@material-ui/core/styles/transitions';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import classNames from 'classnames';

import SwipeableView from './SwipeableView';
import Loading from '../Loading';

const styles = {
  root: {
    '& > *:focus': {
      outline: 'none',
    },
  },
  content: {
    width: '80%',
    maxWidth: 700,
    height: 'calc(100% - 96px)',
    maxHeight: 600,
    margin: '-16px auto 0',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  contentMobile: {
    width: '100%',
    height: '100%',
    maxWidth: 'initial',
    maxHeight: 'initial',
    margin: 0,
    top: 0,
    transform: 'none',
    '& > $carouselWrapper': {
      borderRadius: 0,
    },
  },
  arrow: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: 'calc((100% - 96px) / 2 + 24px)',
  },
  arrowLeft: {
    left: -96,
  },
  arrowRight: {
    right: -96,
  },
  arrowIcon: {
    color: grey[700],
  },
  carouselWrapper: {
    overflow: 'hidden',
    borderRadius: 14,
    transform: 'scale(1.0)',
    background: 'transparent',
    height: '100%',
  },
  dots: {
    paddingTop: 36,
    margin: '0 auto',
  },
  dotsMobile: {
    paddingTop: 0,
  },
  dotsMobileLandscape: {
    paddingTop: 20,
  },
  footer: {
    marginTop: -72,
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  footerMobile: {
    marginTop: -92,
  },
  footerMobileLandscape: {
    marginTop: -3,
    transform: 'translateY(-50vh)',
    display: 'inline-block',
    width: 'auto',
  },
  slide: {
    width: '100%',
    height: '100%',
  },
  slideMobile: {
    width: '100%',
    height: '100%',
  },
  carousel: {
    height: '100%',
  },
  carouselContainer: {
    height: '100%',
  },
  closed: {},
};

class ModalContainer extends Component {
  // eslint-disable-next-line class-methods-use-this
  onChange(newSlideIndex) {
    const { onChange } = this.props;
    console.log('onChange', newSlideIndex);
    onChange(newSlideIndex);
  }

  handleContentClick = (e) => e.stopPropagation() || e.preventDefault();

  handleChange = (index, indexLatest, meta) => {
    if (index === -1) {
      this.decreaseIndex();
    } else {
      this.increaseIndex();
    }
  };

  decreaseIndex() {
    const { slideIndex } = this.props;
    if (slideIndex === 0) {
      return;
    }
    const newSlideIndex = slideIndex - 1;
    this.onChange(newSlideIndex);
  }

  increaseIndex() {
    const { children, onEnd, slideIndex } = this.props;
    const newSlideIndex = slideIndex + 1;
    if (newSlideIndex === children.length) {
      onEnd();
      return;
    }
    this.onChange(newSlideIndex);
  }

  render() {
    const {
      loading,
      children,
      slideIndex,
      onDismissIntention,
      //
      classes,
      containerStyle,
      interval,
      landscape: landscapeProp,
      mobile,
      open,
    } = this.props;

    const landscape = mobile && landscapeProp;
    const transitionDuration = {
      enter: duration.enteringScreen,
      exit: duration.leavingScreen,
    };

    const carousel = (
      <SwipeableView
        className={classes.carousel}
        containerStyle={{ height: '100%', ...containerStyle }}
        index={slideIndex}
        interval={interval}
        onChangeIndex={this.handleChange}
        onClose={() => {}}
        slideClassName={classes.slide}
      >
        {
          React.Children.map(children, (c) => React.cloneElement(c, {
            mobile,
            landscape,
          }))
        }
      </SwipeableView>
    );

    return (
      <Modal
        className={classNames(classes.root, {
          [classes.rootMobile]: mobile,
        })}
        open={open}
        onBackdropClick={onDismissIntention}
        BackdropComponent={Backdrop}
        BackdropProps={{ transitionDuration }}
      >
        <Fade
          appear
          in={open}
          timeout={transitionDuration}
        >
          <div
            className={classNames(classes.content, {
              [classes.contentMobile]: mobile,
            })}
          >
            <Paper
              elevation={mobile ? 0 : 1}
              className={classes.carouselWrapper}
            >
              {carousel}
            </Paper>
            <div>
              <Fab
                className={classNames(classes.arrow, classes.arrowLeft)}
                onClick={() => this.decreaseIndex()}
              >
                <ArrowBackIcon className={classes.arrowIcon} />
              </Fab>
              { loading ? (
                <div className={classNames(classes.arrow, classes.arrowRight)}>
                  <Loading />
                </div>
              ) : (
                <Fab
                  className={classNames(classes.arrow, classes.arrowRight)}
                  onClick={() => this.increaseIndex()}
                >
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </Fab>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onEnd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDismissIntention: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  slideIndex: PropTypes.number.isRequired,
  /** If `false`, the auto play behavior is disabled. */
  autoplay: PropTypes.bool,
  /** Properties applied to the [Button](https://material-ui.com/api/button/) element. */
  ButtonProps: PropTypes.object,
  /** Object for customizing the CSS classes. */
  classes: PropTypes.object.isRequired,
  /** Override the inline-styles of the carousel container. */
  containerStyle: PropTypes.object,
  /** Delay between auto play transitions (in ms). */
  interval: PropTypes.number,
  /** Button text. If not supplied, the button will be hidden. */
  label: PropTypes.string,
  /** If `true`, slide will adjust content for wide mobile screens. */
  landscape: PropTypes.bool,
  /** If `true`, the screen width and height is filled. */
  mobile: PropTypes.bool,
  /** Properties applied to the [Modal](https://material-ui.com/api/modal/) element. */
  ModalProps: PropTypes.object,
  /** Controls whether the AutoRotatingCarousel is opened or not. */
  open: PropTypes.bool,
  /** If `true`, the left and right arrows are hidden in the desktop version. */
  hideArrows: PropTypes.bool
};

ModalContainer.defaultProps = {
  autoplay: true,
  interval: 3000,
  mobile: false,
  open: false,
  hideArrows: false,
};

export default withStyles(styles)(ModalContainer);
