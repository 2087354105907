import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { formatAsCurrency } from '../modules/helpers';
import { galleryEntryShape } from '../shapes';
import EntryResult from './EntryResult';

const useStyles = makeStyles((theme) => ({
  card: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const EntryInList = ({ entry, onOpenHandler, onShareHandler }) => {
  const classes = useStyles();
  const {
    title,
    leaderboard,
    result,
    budgetUsed,
    videoPreview,
  } = entry;
  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={videoPreview}
          title={title}
          onClick={() => onOpenHandler(entry.id)}
        />
        <CardContent>
          <Grid container>
            {leaderboard && (
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
            )}
            <EntryResult result={result} />
          </Grid>
          <Typography variant="body2" color="textSecondary" component="p">
            { `💸 ${formatAsCurrency(budgetUsed)}` }
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={() => onShareHandler(entry.id)}>
            Share
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};


EntryInList.propTypes = {
  entry: galleryEntryShape.isRequired,
  onOpenHandler: PropTypes.func.isRequired,
  onShareHandler: PropTypes.func.isRequired,
};

export default EntryInList;
