import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InstagramIcon from '@material-ui/icons/Instagram';

import logoPNG from '../media/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const NavHeader = () => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <img src={logoPNG} className={classes.menuButton} alt="poly bridge 2 logo" />
        <Typography variant="h6" className={classes.title}>
          PB2 - Gallery
        </Typography>
        <InstagramIcon />
      </Toolbar>
    </AppBar>
  );
};

export default NavHeader;
