import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MUIContainer from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10px',
  },
}));

const Container = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <MUIContainer maxWidth={false} className={classes.root}>
        {children}
      </MUIContainer>
    </>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
