import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import InternalAPIs from '../modules/Api';
import NavHeader from '../components/NavHeader';
import Loading from '../components/Loading';
import EntryInList from '../components/EntryInList';
import Container from '../components/Container';
import theme from '../theme';
import Carousel from '../components/Carousel';

const loadPosts = async (page) => (
  new Promise((resolve) => {
    InternalAPIs.readAll(page).then((data) => {
      const {
        data: entries,
        meta,
      } = data.data;
      resolve({ entries, meta });
    });
  })
);

class GalleryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
      loading: false,
      entries: [],
      meta: null,
      carouselOpen: false,
      selectedEntryId: null,
    };
    this.loadNextPostsPage = this.loadNextPostsPage.bind(this);
    this.selectEntry = this.selectEntry.bind(this);
    this.viewEntry = this.viewEntry.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const {
      entryId,
    } = match.params;
    if (entryId) {
      this.loadEntrySurrounded(entryId);
    } else {
      this.loadPostsInitial();
    }
  }

  async selectEntry(entryId) {
    const { history } = this.props;
    history.push(`/entry/${entryId}`);
    return new Promise((resolve) => {
      this.setState({
        selectedEntryId: entryId,
      }, resolve);
    });
  }

  async viewEntry(entryId) {
    await this.selectEntry(entryId);
    this.setState({
      carouselOpen: true,
    });
  }

  dismissModal() {
    const { history } = this.props;
    history.push('/');
    this.setState({
      carouselOpen: false,
    });
  }

  loadNextPostsPage() {
    const { meta } = this.state;
    const { current_page: currentPage, last_page: lastPage } = meta;
    const nextPage = currentPage + 1;
    if (nextPage > lastPage) {
      return;
    }
    this.setState({
      loading: true,
    }, () => this.loadPostsInPage(nextPage));
  }

  async loadPostsInPage(page) {
    const { entries: stateEntries } = this.state;
    const { entries } = await loadPosts(page);
    this.setState({
      loading: false,
      entries: [...stateEntries, ...entries],
    });
  }

  async loadPostsInitial() {
    const { entries, meta } = await loadPosts(null);
    this.setState({
      initialLoading: false,
      entries,
      meta,
    });
  }

  async loadEntrySurrounded(entryId) {
    InternalAPIs.readOneSurrounded(entryId).then((data) => {
      const {
        data: entries,
        meta,
      } = data.data;
      this.setState({
        initialLoading: false,
        entries,
        meta,
        selectedEntryId: entryId,
        carouselOpen: true,
      });
    });
  }

  render() {
    const {
      initialLoading,
      loading,
      entries,
      carouselOpen,
      selectedEntryId,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <NavHeader />
        <Container>
          {initialLoading ? (
            <Loading />
          ) : (
            <>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="space-around"
                alignItems="flex-start"
              >
                {entries.map((entry) => (
                  <EntryInList
                    key={entry.id}
                    entry={entry}
                    onOpenHandler={this.viewEntry}
                    onShareHandler={this.viewEntry}
                  />
                ))}
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="space-around"
                alignItems="center"
                style={{ marginTop: 30, marginBottom: 20 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.loadNextPostsPage}
                  disabled={loading}
                >
                  Load More
                  { <ExpandMoreIcon /> }
                </Button>
              </Grid>
            </>
          )}
        </Container>
        { entries.length > 0 && (
          <Carousel
            isOpen={carouselOpen}
            entries={entries}
            selectedEntryId={selectedEntryId}
            isLoadingMoreEntries={loading}
            loadMore={this.loadNextPostsPage}
            selectEntry={this.selectEntry}
            onDismissIntention={this.dismissModal}
          />
        )}
      </ThemeProvider>
    );
  }
}

GalleryView.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default GalleryView;
