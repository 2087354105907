import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  win: {
    color: theme.status.success,
  },
  lose: {
    color: theme.status.danger,
  },
}));

const EntryResult = ({ result }) => {
  const classes = useStyles();
  if (result === 'win') {
    return (
      <Typography
        className={`${classes.root} ${classes.win}`}
        variant="button"
        display="block"
        align="right"
        gutterBottom
      >
        win
      </Typography>
    );
  }
  return (
    <Typography
      className={`${classes.root} ${classes.lose}`}
      variant="button"
      display="block"
      align="right"
      gutterBottom
    >
      lose
    </Typography>
  );
};

EntryResult.propTypes = {
  result: PropTypes.string.isRequired,
};

export default EntryResult;
