import React from 'react';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import GalleryView from './views/GalleryView';
import FourOFour from './views/FourOFour';

const Routes = () => (
  <Router history={createBrowserHistory()}>
    <Switch>
      <Route exact path="/" component={GalleryView} />
      <Route exact path="/entry/:entryId" component={GalleryView} />
      <Route component={FourOFour} status={404} />
    </Switch>
  </Router>
);

export default Routes;
