import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { isMobile } from 'react-device-detect';
import { galleryEntryShape } from '../../shapes';
import Slide from './Slide';
import VideoPlayer from '../VideoPlayer';
import ModalContainer from './ModalContainer';

const entryIdToIndex = (entries, selectedEntryId) => {
  if (!selectedEntryId) {
    return 0;
  }
  const index = entries.findIndex((entry) => entry.id === selectedEntryId);
  if (index === -1) {
    return 0;
  }
  return index;
};

const indexToEntry = (index, entries) => (entries[index].id);

const Index = ({
  isOpen,
  entries,
  selectedEntryId,
  isLoadingMoreEntries,
  loadMore,
  onDismissIntention,
  selectEntry,
}) => {
  const selectedIndex = entryIdToIndex(entries, selectedEntryId);
  return (
    <ModalContainer
      open={isOpen}
      onEnd={() => loadMore()}
      onChange={(newIndex) => { selectEntry(indexToEntry(newIndex, entries)); }}
      onDismissIntention={() => onDismissIntention()}
      loading={isLoadingMoreEntries}
      slideIndex={selectedIndex}
      mobile={isMobile}
      landscape
      button
    >
      {entries.map((entry) => (
        <Slide
          key={entry.id}
          media={(
            <VideoPlayer
              cover={entry.videoPreview}
              video={entry.video}
            />
          )}
          entry={entry}
          landscape
          initialCount={entries.length}
          slideIndex={selectedIndex}
        />
      ))}
    </ModalContainer>
  );
};

Index.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoadingMoreEntries: PropTypes.bool.isRequired,
  entries: arrayOf(galleryEntryShape).isRequired,
  selectedEntryId: PropTypes.string,
  loadMore: PropTypes.func.isRequired,
  onDismissIntention: PropTypes.func.isRequired,
  selectEntry: PropTypes.func.isRequired,
};

Index.defaultProps = {
  selectedEntryId: null,
};

export default Index;
