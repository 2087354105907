export const formatAsCurrency = (amount) => (
  new Intl.NumberFormat(
    'en-US', { style: 'currency', currency: 'USD' },
  ).format(amount)
);

export const formatAsNumber = (amount) => (
  new Intl.NumberFormat(
    'en-US', {},
  ).format(amount)
);
