import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import { galleryEntryShape } from '../../shapes';
import { formatAsCurrency, formatAsNumber } from '../../modules/helpers';
import Sharer from './Sharer';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: blue[500],
    height: '100%',
  },
  rootMobileLandscape: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  media: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      maxHeight: '100%',
    },
  },
  mediaMobile: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  mediaMobileLandscape: {},
  mediaBackground: {
    backgroundColor: blue[700],
    height: 'calc(100% - 216px)',
    textAlign: 'center',
  },
  mediaBackgroundMobile: {
    height: 'calc(100% - 241px)',
  },
  mediaBackgroundMobileLandscape: {
    height: '100%',
    flex: '1 1',
    alignSelf: 'stretch',
  },
  textTitles: {
    textAlign: 'center',
    maxWidth: '80%',
    margin: '0 auto',
    paddingTop: 32,
  },
  textTitlesMobile: {
    paddingTop: 30,
    '& $title': {
      marginBottom: 8,
    },
  },
  textTitlesMobileLandscape: {
    minWidth: 300,
    maxWidth: 'calc(50% - 48px)',
    padding: '24px 24px 128px',
    flex: '0 1',
    alignSelf: 'center',
    textAlign: 'left',
    margin: 0,
  },
  textDetails: {
    textAlign: 'left',
    maxWidth: '90%',
    margin: '0 auto',
    paddingTop: 10,
  },
  textStepper: {
    position: 'absolute',
    bottom: 5,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#fff',
  },
  subtitle: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
    color: '#fff',
  },
  details: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
    color: '#fff',
  },
  sharer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '0 auto',
    maxWidth: '90%',
  },
  stepper: {
    background: 'none',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
    color: '#fff',
  },
}));

const Slide = (props) => {
  const classes = useStyles();
  const {
    media,
    slideIndex,
    initialCount,
    mediaBackgroundStyle,
    entry,
    mobile,
    landscape,
    ...other
  } = props;

  const mobileLandscape = mobile && landscape;

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootMobile]: mobile,
        [classes.rootMobileLandscape]: mobileLandscape,
      })}
      {...other}
    >
      <Helmet>
        <title>{entry.title}</title>
        <meta property="og:title" content="Poly Bridge 2 replay" />
        <meta property="og:type" content="video.movie" />
        <meta property="og:site_name" content="gallery.polybridge2.com" />
        <meta property="og:description" content="Have a look at this cool replay of poly bridge 2" />
        <meta property="og:video" content={entry.video} />
        <meta property="og:image" content={entry.videoPreview} />
      </Helmet>
      <div
        className={classNames(classes.mediaBackground, {
          [classes.mediaBackgroundMobile]: mobile,
          [classes.mediaBackgroundMobileLandscape]: mobileLandscape,
        })}
        style={mediaBackgroundStyle}
      >
        <div className={classNames(classes.media, {
          [classes.mediaMobile]: mobile,
          [classes.mediaMobileLandscape]: mobileLandscape,
        })}
        >
          {media}
        </div>
      </div>
      <div
        className={classNames(classes.textTitles, {
          [classes.textTitlesMobile]: mobile,
          [classes.textTitlesMobileLandscape]: mobileLandscape,
        })}
      >
        <Typography className={classes.title}>
          {entry.title}
        </Typography>
        <Typography className={classes.subtitle}>
          World?
        </Typography>
      </div>
      <div className={classes.textDetails}>
        <Typography className={classes.details}>
          { `💸 ${formatAsCurrency(entry.budgetUsed)}` }
        </Typography>
        <Typography className={classes.details}>
          { `😞 ${formatAsNumber(entry.maxStress)}` }
        </Typography>
      </div>
      <div className={classes.sharer}>
        <Sharer
          title={entry.title}
          url={window.location.href}
        />
      </div>
      <div className={classes.textStepper}>
        <Typography className={classes.stepper}>
          { /* Indexes are 0 based */ }
          {`${(slideIndex + 1)} / ${initialCount}`}
        </Typography>
      </div>
    </div>
  );
}

Slide.propTypes = {
  media: PropTypes.node.isRequired,
  initialCount: PropTypes.number.isRequired,
  slideIndex: PropTypes.number.isRequired,
  mediaBackgroundStyle: PropTypes.object,
  entry: galleryEntryShape.isRequired,
  mobile: PropTypes.bool,
  landscape: PropTypes.bool,
};

export default Slide;
