import axios from 'axios';
import { storeLog, LOG_LEVEL_ERROR } from '../Logger';

export default class Api {
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      // TODO Needs investigaton
      // A timeout of ~1000 will fail upon the first request
      timeout: 30000,
      headers: {
        Accept: 'application/json',
      },
    });
    // TODO Intercept auth tokens?
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        // Error
        if (error.response) {
          const { status } = error.response;
          if (status === 404) {
            // window.location.href = '/404';
          }
        } else if (error.request) {
          // TODO review here
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // 422 unprocessable entity comes here!
          storeLog('Network error: 500 or Server down', LOG_LEVEL_ERROR);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        storeLog(error, LOG_LEVEL_ERROR);
        return Promise.reject(error);
      },
    );
  }
}
